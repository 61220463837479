export default [
  {
    value: "cameraName",
    text: "Camera",
    width: 110,
    sortable: true,
    filterable: true,
  },
  {
    value: "requesterName",
    text: "Requested By",
    width: 110,
    sortable: true,
    filterable: true,
  },
  {
    value: "title",
    text: "Title",
    width: 110,
    sortable: true,
  },
  {
    value: "status",
    text: "Status",
    width: 110,
    sortable: true,
  },
  {
    value: "type",
    text: "Type",
    width: 110,
    sortable: true,
  },
  {
    value: "fromDate",
    text: "From Date",
    width: 150,
    sortable: true,
  },
  {
    value: "toDate",
    text: "To Date",
    width: 150,
    sortable: true,
  },
  {
    value: "createdAt",
    text: "Created At",
    width: 150,
    sortable: true,
  },
  {
    value: "completedAt",
    text: "Completed in",
    width: 150,
    sortable: true,
  },
  {
    value: "embedCode",
    text: "Embed Code",
    align: "center",
    width: 50,
    sortable: false,
  },
  {
    value: "download",
    text: "Download",
    align: "center",
    width: 80,
    sortable: false,
  },
]
